import React from "react";
import { useMatch, useResolvedPath} from "react-router-dom";
import {useTranslations} from "../../contexts/LanguageProvider";
import { HashLink as Link } from 'react-router-hash-link';

const CustomLink = ({children, to, ...props}) => {
    const {translate} = useTranslations()
    let resolved = useResolvedPath(to);
    let match = useMatch({path: resolved.pathname, end: true});

    return (
        <Link
            style={{textDecoration: match ? "underline" : "none"}}
            to={to} smooth
            {...props}>
            {translate(children) || children}
        </Link>
    );
}

export default CustomLink