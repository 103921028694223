import './styles/expoCard.scss'
export const ExpoCard = ({ img, title, date, description }) => {
  return (
    <div className="expoCard">
      <div className="expoCard__img">
      </div>
      <div className="expoCard__title">
        <h3>
          <a href='/'>{title}</a>
        </h3>
      </div>
      <div className="expoCard__line"></div>
      <div className="expoCard__date"> <p>{date}</p></div>
      <div className="expoCard__description">
        <p>{description}</p>
      </div>
    </div>
  )
}