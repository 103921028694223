export const routes = [
    {
        active: false,
        path: '/exposiciones',
        label: 'navbar.expositions',
        children: []
    },
    {
        active: false,
        path: '/ferias',
        label: 'navbar.fairs',
        children: []
    },
    {
        active: true,
        path: '/artistas',
        label: 'navbar.artists',
        children: []
    },
    {
        active: true,
        path: '/galeria',
        label: 'navbar.gallery.title',
        children: [
            {
                path: '/#informacion',
                label: 'navbar.gallery.information',
            },
            {
                path: '/#servicios',
                label: 'navbar.gallery.services.title'
            },
            {
                path: '/servicios/#certificado-autenticidad',
                label: 'navbar.gallery.services.certificate_of_authenticity'
            },
            {
                path: '/servicios/#perito-arte',
                label: 'navbar.gallery.services.art_expert'
            },
            {
                path: '/servicios/#tasacion-cuadros',
                label: 'navbar.gallery.services.painting_appraisal'
            }
        ]
    }
]