import './styles/footer.scss'
import React from 'react'
import {useTranslations} from '../../contexts/LanguageProvider'
import CustomLink from '../CustomLink'

const Footer = () => {
    const {translation: {footer, navbar, company}} = useTranslations()

    const copyright = footer.copyright.replace('YEAR', new Date().getFullYear());

    return (
        <div className='footer px-sm-3 px-md-3 px-lg-5 px-xl-5'>
            <footer className='py-3'>
                <div className='row'>
                    <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2'></div>
                    <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2'>
                        <ul className='nav d-flex flex-column'>
                            <li className='nav-item nav-link py-0 p-lg-0 p-xl-0 link'>
                                <CustomLink to={'/galeria/#servicios'}>
                                    <h5>{footer.sections.services}</h5>
                                </CustomLink>
                            </li>
                            <li className='nav-item mb-2 nav-link px-4 py-0 p-lg-0 p-xl-0 link'>
                                <CustomLink to={'/galeria/servicios/#certificado-autenticidad'}>
                                    {navbar.gallery.services.certificate_of_authenticity}
                                </CustomLink>
                            </li>
                            <li className='nav-item mb-2 nav-link px-4 py-0 p-lg-0 p-xl-0 link'>
                                <CustomLink to={'/galeria/servicios/#perito-arte'}>
                                    {navbar.gallery.services.art_expert}
                                </CustomLink>
                            </li>
                            <li className='nav-item mb-2 nav-link px-4 py-0 p-lg-0 p-xl-0 link'>
                                <CustomLink to={'/galeria/servicios/#tasacion-cuadros'}>
                                    {navbar.gallery.services.painting_appraisal}
                                </CustomLink>
                            </li>
                        </ul>
                    </div>

                    <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-2'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4'/>
                            <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 mx-3'>
                                <h5>{footer.sections.contact}</h5>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4'/>
                            <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8 my-2'>
                                <ul className='nav list-unstyled d-flex pe-5'>
                                    <li className='ms-4 link'>
                                        <a className='social-icon' href={`tel:${company.phone}`}>
                                            <i className='bi bi-telephone-outbound' style={{fontSize: '1.5rem'}}/>
                                        </a>
                                    </li>
                                    <li className='ms-4 link'>
                                        <a
                                            className='social-icon'
                                            href={`https://wa.me/${company.wa.phone}?text=${company.wa.message}`}
                                        >
                                            <i className='bi bi-whatsapp' style={{fontSize: '1.5rem'}}/>
                                        </a>
                                    </li>
                                    <li className='ms-4 link'>
                                        <a
                                            className='social-icon'
                                            href={`mailto:${company.mail.to}?Subject=${company.mail.subject}`}
                                        >
                                            <i className='bi bi-envelope' style={{fontSize: '1.5rem'}}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-top mt-4'>
                    <p className='text-center text-muted'>{copyright}</p>
                </div>
            </footer>
        </div>
    )
}

export default Footer
