import './styles/selectedWorksCard.scss'
import {Image} from "../../../../../../components/image";

export const SelectedWorksCard = ({title, year, description, image}) => {
    return (
        <div className="selectedWorksCard">
            <div className="selectedWorksCard__image">
                <Image title={title} imgSrc={image} section={'pictures'}/>
            </div>
            <div className="selectedWorksCard__info">
                {title && <p><b>{title}</b></p>}
                {year && <p>{year}</p>}
                {description && <p>{description}</p>}
            </div>
        </div>
    )
}