/** Components */
import { Image } from '../../components/image';
import { FairCard } from './components/fairCard';
import { Layout } from '../../containers/layout';
/** Styles */
import './styles/fairs.scss';
import { useTranslations } from '../../contexts/LanguageProvider';

export const Fairs = () => {
	const {
		translate,
		translation: {
			fairs: { secondary },
		},
	} = useTranslations();

	return (
		<div className='fairs'>
			<div className='fairs__intro'>
				<div className='fairs__intro-info'>
					<h1>{translate('fairs.principal.title')}</h1>
					<span>{translate('fairs.principal.date')}</span>
				</div>
				<div className='fairs__intro-image'>
					<Image title='Basel' />
				</div>
			</div>
			<Layout section='' applyPadding color='#fff'>
				<div className='fairs__historical'>
					<div className='fairs__historical-title'>
						{translate('fairs.past')}
					</div>
					<div className='fairs__historical-content'>
						{secondary.map((fair) => {
							return (
								<FairCard
									key={`${fair.title}_${fair.startDate}`}
									image={fair.image}
									startDate={fair.startDate}
									endDate={fair.endDate}
									title={fair.title}
									description={fair.description}
									moreInfo={fair.moreInfo}
								/>
							);
						})}
					</div>
				</div>
			</Layout>
		</div>
	);
};
