import './styles/service.scss'
import { Image } from "../../../../../../components/image";

export const Service = ({id, title, description, image}) => {
    return (
        <section className="information" id={id}>
            {image.position === 'left' &&<div className="information__image">
                <Image imgSrc={image.url} section='images' title={title}/></div>}
            <div className="information__menu">
                <div className="information__menu-title">
                    <h2>{title}</h2>
                </div>
                <div className="information__menu-description">
                    <p dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br />')}}/>
                </div>
            </div>
            {image.position === 'right' && <div className="information__image">
                <Image imgSrc={image.url} section='images' title={title}/></div>}
        </section>
    )
}