import './styles/artistsCard.scss'
import CustomLink from "../../CustomLink";
import { Image } from "../../image";

const ArtistsCard = ({person}) => {
    return (
        <div className="artistsCard">
            <CustomLink className="artistsCard__card-link" to={person.id}/>
            <Image
                imgSrc={person.image}
                alt={`${person.name} ${person.lastname}`}
                className="artistsCard__image"/>
            <div className="artistsCard__text-wrapper">
                <h2 className="artistsCard__title">{`${person.name} ${person.lastname}`}</h2>
                <div className="artistsCard__details-wrapper">
                    <div className="artistsCard__place">{person.place}</div>
                    <div className="artistsCard__style">{person.styles}</div>
                    <div className="artistsCard__work">{person.work}</div>
                </div>
            </div>
        </div>
    )
}

export default ArtistsCard