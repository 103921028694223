/** Styles */
import './styles/services.scss'
import {Service} from "./components/Service";
import {useTranslations} from "../../../../contexts/LanguageProvider";

export const Services = () => {
    const { translation: {services}} = useTranslations()

    return(
    <section id={"servicios"} className="services">
      <div className="services__title">
        <h2>{services.title}</h2>
      </div>
      <div className="services__content">
          <Service {...services.certificateOfAuthenticity} id={"certificado-autenticidad"}/>
          <Service {...services.artExpert} id={"perito-arte"}/>
          <Service {...services.artAppraiser} id={"tasacion-cuadros"}/>
      </div>
    </section>
  )
}