import React, {useState} from 'react';
import {routes} from '../../constants'
import CustomLink from '../CustomLink'
import DropdownLink from './components/DropdownLink'
import {HashLink as Link} from 'react-router-hash-link';
import './styles/navbar.scss'
import {useTranslations} from "../../contexts/LanguageProvider";
import {Image} from "../image";
import {Language} from "../Language";

const Navbar = () => {
    const {translation: {company}} = useTranslations()
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const updateNavbar = () => setIsNavCollapsed(!isNavCollapsed)

    const createLink = ({path, label}) => {
        return <li className="nav-item" key={path}>
            <CustomLink to={path} className='nav-link' onClick={updateNavbar}>{label}</CustomLink>
        </li>
    }

    return (
        <nav className="navbar sticky-top navbar-expand-lg link">
            <div className="container-fluid">
                <Link className="navbar-brand font-weight-bolder" to="/">
                    <div style={{height: '50px', width: '70px'}}>
                        <Image imgSrc={company.images.logo} section='images' title="Logo" className="vertical-align-middle"/>
                        {/*<span className="fs-5">{translate('company.name')}</span>*/}
                    </div>
                </Link>
                {/* Hamburger button */}
                <button className={`custom-toggler navbar-toggler btn-sm hamburger ${!isNavCollapsed && 'active'}`}
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbar-hamburger"
                        aria-controls="navbar-hamburger"
                        aria-expanded={!!isNavCollapsed}
                        aria-label="Toggle navigation"
                        onClick={updateNavbar}>
                    <span className="line"/>
                    <span className="line"/>
                    <span className="line"/>
                </button>

                <div className={`navbar-collapse ${isNavCollapsed ? 'collapse' : ''} justify-content-end`}
                     id="navbar-hamburger">
                    <ul className="navbar-nav">
                        {routes.reduce((acc, next) => {
                            if(!next.active) return acc
                            if (next.children.length) {
                                acc.push(<DropdownLink key={next.path} onClick={updateNavbar} {...next}/>)
                            } else {
                                acc.push(createLink(next))
                            }
                            return acc
                        }, [])}
                    </ul>
                  <Language/>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;