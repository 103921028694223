/** Components */
import {ExhibitionCard} from './components/exhibitionCard'
/** Styles */
import './styles/exhibitionsSection.scss'
import {useTranslations} from "../../../../contexts/LanguageProvider";

export const ExhibitionsSection = ({exhibitions}) => {
    const {translate} = useTranslations()

    return (
        <div className="artist-exhibitionsSection" id={"exhibitions"}>
            <div className="artist-exhibitionsSection__title">
                <h2>{translate('artist.sections.exhibitions.label')}</h2>
            </div>
            <div className="artist-exhibitionsSection__content">
                {
                    exhibitions.map(exhibition => {
                        return <ExhibitionCard
                            key={`${exhibition.artistName}_${exhibition.location}`}
                            artistName={exhibition.artistName}
                            location={exhibition.location}
                            startDate={exhibition.startDate}
                            endDate={exhibition.endDate}
                        />
                    })
                }
            </div>
        </div>
    )
}