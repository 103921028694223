import { Image } from '../../../../components/image'
import './styles/fairCard.scss'

export const FairCard = ({ image, startDate, endDate, title, description, moreInfo }) => {
  return (
    <div className='fairCard'>
      <div className='fairCard__image'>
        <Image title={title} />
      </div>
      <div className='fairCard__date'>
        {startDate} - {endDate}
      </div>
      <div className='fairCard__title'>
        <h3>{title}</h3>
      </div>
      <div className='fairCard__description'>
        <p>{description}</p>
      </div>
      <div className='fairCard__moreInfo'></div>
    </div>
  )
}
