import React from 'react';
import './styles/language.scss'
import {useTranslations} from "../../contexts/LanguageProvider";

export const Language = ()=>{
    const {language,languages,updateLanguage, translate} = useTranslations()

    return (
        <ul className="navbar-nav">
            <li className="nav-item dropdown me-2">
                <a className="nav-link dropdown-toggle" href="/" id="navbarLanguagesSelector"
                   role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={`${process.env.PUBLIC_URL}/assets/languages/${language}.webp`} className="language-switcher__flag"
                         alt={`${language} flag`}/>
                </a>
                <ul className="dropdown-menu dropdown-menu-lg-end py-0"
                    aria-labelledby="navbarLanguagesSelector">
                    {Object.keys(languages).filter(lang => lang!== language).map(lang=>{
                        return (
                            <li key={`language-${lang}`}>
                                <button className="dropdown-item language-switcher__content" id="navbarLanguagesSelector"
                                        data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>updateLanguage(lang)}>
                                    <img src={`${process.env.PUBLIC_URL}/assets/languages/${lang}.webp`} className="language-switcher__flag"
                                         alt={`${lang} flag`}/>
                                    {translate(`languages.${lang}`)}
                                </button>
                            </li>
                        )
                    })}
                </ul>
            </li>
        </ul>
    )
}

/**
 *     <div className="language-switcher dropdown dropdown--open">
 *             <div className="dropdown-handle language-switcher__handle">
 *                 <img src="https://assets.codepen.io/197832/us.png" className="language-switcher__flag" alt="us flag"/>
 *                 English
 *                 <img src="https://dt-cdn.net/images/chevron-down-fdc0c33ccb.svg" className="dropdown-arrow"/>
 *             </div>
 *             <div className="dropdown-content language-switcher__content">
 *                 <a className="">
 *                     <img src="https://assets.codepen.io/197832/fr.png" className="language-switcher__flag"
 *                          alt="france flag"/>
 *                     French
 *                 </a>
 *                 <a className="">
 *                     <img src="https://assets.codepen.io/197832/de.png" className="language-switcher__flag"
 *                          alt="german flag"/>
 *                     German
 *                 </a>
 *                 <a className="">
 *                     <img src="https://assets.codepen.io/197832/jp.png" className="language-switcher__flag"
 *                          alt="japan flag"/>
 *                     Japanses
 *                 </a>
 *                 <a className="">
 *                     <img src="https://assets.codepen.io/197832/cn.png" className="language-switcher__flag"
 *                          alt="china flag"/>
 *                     Mandarin
 *                 </a>
 *                 <a className="">
 *                     <img src="https://assets.codepen.io/197832/pr.png" className="language-switcher__flag"
 *                          alt="portugal flag"/>
 *                     Portuguese
 *                 </a>
 *                 <a className="">
 *                     <img src="https://assets.codepen.io/197832/es.png" className="language-switcher__flag"
 *                          alt="spain flag"/>
 *                     Spanish
 *                 </a>
 *             </div>
 *         </div>
 */