/** Styles */
import './styles/biographySection.scss'

export const BiographySection = ({main,secondary}) => {
  return(
    <div className="artistBio" id={"biography"}>
      <div className="artistBio__main">
        <p>{main}</p>
      </div>
      <div className="artistBio__secondary">
        {secondary.map((description,index) => {
          return <p key={`biography_${index}`}>{description}</p>
        })}
      </div>
    </div>
  )
}