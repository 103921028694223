import React from "react";
import CustomLink from "../../../CustomLink";

import { useTranslations } from "../../../../contexts/LanguageProvider";
import { Link } from "react-router-dom";

const DropdownLink = ({label, path, children, onClick}) => {
    const {translate} = useTranslations()
    return (
        <li className="nav-item dropdown me-2">
            <Link className={`nav-link dropdown-toggle`}
                  to="#"
                  id={`navbar-dropdown-menu-link-${label}`}
                  role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                {translate(label)}
            </Link>
            <ul className="dropdown-menu dropdown-menu-lg-end py-0" aria-labelledby={`navbar-dropdown-menu-link-${label}`}>
                {children.map((link, index) => {
                    const route = `${path}${link.path}`
                    return <CustomLink
                        key={`${route}-${index}`}
                        to={route}
                        className="dropdown-item" onClick={onClick}>
                        {link.label}
                    </CustomLink>
                })}
            </ul>
        </li>
    )
}

export default DropdownLink