import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import App from "../pages/App";
import Home from "../pages/Home";
import Expositions from "../pages/Expositions";
import { Fairs } from "../pages/Fairs";
import Artists from "../pages/Artists";
import Artist from "../pages/Artist";
import Gallery from "../pages/Gallery";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Home/>}/>
                    <Route path="exposiciones" element={<Expositions/>}>
                        <Route path=":id" element={<Expositions/>}/>
                    </Route>
                    <Route path="ferias" element={<Fairs/>}>
                        <Route path=":id" element={<Fairs/>}/>
                    </Route>
                    <Route path="artistas" element={<Artists/>} />
                    <Route path="artistas/:name" element={<Artist/>}/>
                        
                    <Route path="galeria" element={<Gallery/>}>
                        <Route path="servicios" element={<Gallery/>}>
                            <Route path="certificado-autenticidad" element={<Gallery/>}/>
                            <Route path="perito-arte" element={<Gallery/>}/>
                            <Route path="tasacion-cuadros" element={<Gallery/>}/>
                        </Route>
                    </Route>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Route>
            </Routes>
        </Router>
    );
};

export default AppRoutes;
