import './styles/information.scss';
import { useTranslations } from '../../../../contexts/LanguageProvider';
import {Image} from "../../../../components/image";

export const Information = () => {
	const { translate } = useTranslations();
	return (
		<div id='informacion' className='info'>
			<div className='info__image' >
				<Image imgSrc='galeria-lopez-torres.jpeg' section='images' title={translate('company.name')}/>
			</div>
			<div className='info__menu'>
				<div className='info__menu-title'>
					<h1>{translate('company.name')}</h1>
				</div>
				<div className='info__menu-description'>
					<p>{translate('company.description')}</p>
				</div>
			</div>
		</div>
	);
};
