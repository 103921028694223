const $ = require('jquery')

class Particle {
    constructor(canvas) {
        let random = Math.random()
        this.progress = 0;
        this.canvas = canvas;
        this.center = {
            x: $(window).width() / 2,
            y: $(window).height() / 2
        }
        this.point_of_attraction = {
            x: -$(window).width() ,
            y: -$(window).height()  // -50 para subir el punto
        }

        if (Math.random() > 0.5) {
            this.x = $(window).width() * Math.random()
            this.y = Math.random() > 0.5 ? -Math.random() - 100 : $(window).height() + Math.random() + 100
        } else {
            this.x = Math.random() > 0.5 ? -Math.random() - 100 : $(window).width() + Math.random() + 100
            this.y = $(window).height() * Math.random()

        }

        this.s = Math.random() * 2;
        this.a = 0
        this.w = $(window).width()
        this.h = $(window).height()
        this.radius = random > .2 ? Math.random() * 1 : Math.random() * 3
        this.color = '#' + Math.floor(random * 16777215).toString(16);
        this.radius = random > .8 ? Math.random() * 2.2 : this.radius
        //this.color = random > .8 ? "#3CFBFF" : this.color
    }

    calculateDistance(v1, v2) {
        let x = Math.abs(v1.x - v2.x);
        let y = Math.abs(v1.y - v2.y);
        return Math.sqrt((x * x) + (y * y));
    }

    render() {
        this.canvas.beginPath();
        this.canvas.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        this.canvas.lineWidth = 2;
        this.canvas.fillStyle = this.color;
        this.canvas.fill();
        this.canvas.closePath();
    }

    move() {
        let p1 = {
            x: this.x,
            y: this.y
        }
        let distance = this.calculateDistance(p1, this.point_of_attraction)
        let force = Math.max(100, (1 + distance));

        let attr_x = (this.point_of_attraction.x - this.x) / force;
        let attr_y = (this.point_of_attraction.y - this.y) / force;

        this.x += (Math.cos(this.a) * (this.s)) + attr_x;
        this.y += (Math.sin(this.a) * (this.s)) + attr_y;
        this.a += (Math.random() > 0.5 ? Math.random() * 0.9 - 0.45 : Math.random() * 0.4 - 0.2);

        if (distance < (30 + Math.random() * 100)) {
            return false;
        }
        this.render();
        this.progress++;
        return true;
    }
}

class Animation {
    MAX_PATICLES = 2500;
    PARTICLES = [];
    FRENQUENCY = 30;
    INIT_NUM = this.MAX_PATICLES;
    MAX_TIME = this.FRENQUENCY * this.MAX_PATICLES;
    TIME_TO_RECREATE = false;
    setTimeouts = []
    requests = []

    initializeCanvas() {
        const self = this
        this.canvas = document.getElementById('particle-canvas');
        this.canvas.width = $(window).width()//window.innerWidth//this.canvas.offsetWidth * window.devicePixelRatio;
        this.canvas.height = $(window).height()//window.innerHeight//this.canvas.offsetHeight * window.devicePixelRatio;
        this.ctx = this.canvas.getContext('2d');

        window.addEventListener('resize', () => {
            self.canvas.width =  $(window).width()//window.innerHeight//self.canvas.offsetWidth * window.devicePixelRatio;
            self.canvas.height =  $(window).height()//window.innerWidth//self.canvas.offsetHeight * window.devicePixelRatio;
            self.ctx = self.canvas.getContext('2d');
        });
    }

    popolate(num = this.MAX_PATICLES) {
        const self = this
        for (let i = 0; i < num; i++) {
            this.setTimeouts.push(
                setTimeout(() => {
                    //Add particle
                    self.PARTICLES.push(new Particle(self.ctx, self.canvas.height, self.canvas.width))
                }, self.FRENQUENCY * i)
            )
        }
        return self.PARTICLES.length
    }

    update() {
        this.PARTICLES = this.PARTICLES.filter(function (p) {
            return p.move()
        })

        // Recreate PARTICLES
        if (this.TIME_TO_RECREATE) {
            if (this.PARTICLES.length < this.INIT_NUM) {
                this.popolate(10);
            }
        }
        this.clear();
        this.requests.push(requestAnimationFrame(this.update.bind(this)))
    }


    clear() {
        this.ctx.globalAlpha = 0.08;
        this.ctx.fillStyle = '#110031';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.globalAlpha = 1;
    }

    startAnimation() {
        this.initializeCanvas();
        const self = this

        // Enable repopolate
        this.setTimeouts.push(
            setTimeout(() => {
                self.TIME_TO_RECREATE = true;
            }, self.MAX_TIME)
        )

        this.popolate()

        this.update()
    }

    cleanAll() {
        if (this.requests.length) {
            this.requests.forEach(cancelAnimationFrame)
            this.requestId = []
        }
        this.setTimeouts.forEach(clearTimeout)
        console.log('clear')
    }
}

export default new Animation()