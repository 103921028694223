/** Components */
import {SelectedWorksCard} from './components/selectedWorksCard'
/** Styles */
import './styles/selectedWorksSection.scss'
import {useTranslations} from "../../../../contexts/LanguageProvider";

export const SelectedWorksSection = ({selectedWorks}) => {
    const {translate} = useTranslations()

    return (
        <div className="artist-selectedWorksSection" id={"works"}>
            <div className="artist-selectedWorksSection__title">
                <h2>{translate('artist.sections.works.label')}</h2>
            </div>
            <div className="artist-selectedWorksSection__content">
                {selectedWorks.map((work,index) => {
                    return <SelectedWorksCard
                        key={`${work.name}_${work.year}-${index}`}
                        {...work}
                    />
                })}
            </div>
        </div>
    )
}