import './styles/exhibitionCard.scss'

export const ExhibitionCard = ({ artistName, location, startDate, endDate}) => {
  return(
    <div className="exhibitionCard">
      <div className="exhibitionCard__image"></div>
      <div className="exhibitionCard__info">
        <span className="location">{location}</span>
        <span className="artist"><h3>{artistName}</h3></span>
        <span className="date">{startDate} - {endDate}</span>
      </div>
    </div>
  )
}