import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useMongoDB} from '../MongoDB'
import get from 'lodash.get'
import es from '../../data/es.json'
import en from '../../data/en.json'
import pt from '../../data/pt.json'
import fr from '../../data/fr.json'

const languages = {es, en, pt, fr}

const LanguageContext = React.createContext({
    language: process.env.REACT_APP_INIT_LANGUAGE,
    translation: languages.es,
    updateLanguage: () => {
    }
})

const LanguageProvider = ({children}) => {
    const {db, fetchTranslations} = useMongoDB()
    const [{language, translation}, setLanguage] = useState({
        language: process.env.REACT_APP_INIT_LANGUAGE,
        translation: languages.es
    })
    const initialTextsLoaded = useRef(false)

    const updateLanguage = useCallback(
        async (newLang) => {
            if ((initialTextsLoaded.current && newLang === language) || !db) return
            const newTranslation = await fetchTranslations({language: newLang})
            initialTextsLoaded.current = true
            setLanguage({language: newLang, translation: newTranslation || languages[newLang]})
        },
        [language, db, fetchTranslations])

    useEffect(() => {
        updateLanguage(language)
    }, [language, updateLanguage])

    const translate = (label, byDefault) => get(translation, label, byDefault || label)

    const context = {
        language,
        languages,
        translation,
        translate,
        updateLanguage: updateLanguage
    }

    return (
        <LanguageContext.Provider value={context}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useTranslations = () => {
    const languageContext = useContext(LanguageContext)
    if (languageContext == null) {
        throw new Error('useTranslations() called outside of a RealmApp?')
    }
    return languageContext
}

export default LanguageProvider
