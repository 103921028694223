import './styles/layout.scss';

export const Layout = ({
	section = '',
	children,
	applyPadding = true,
	color,
}) => {
	return (
		<div
			className='layout'
			style={{ backgroundColor: color || 'rgb(250,250,250)' }}
		>
			<div className='layout__section'>
				<div className='layout__section-overlay'>
					<div className='text'>{section}</div>
				</div>
			</div>
			<div className={`layout__content${applyPadding ? '' : '-full'}`}>
				{children}
			</div>
		</div>
	);
};
