import './styles/introSection.scss'
import CustomLink from "../../../../components/CustomLink";
import {Image} from "../../../../components/image";
import {useTranslations} from "../../../../contexts/LanguageProvider";

export const IntroSection = ({image, name, lastname, images}) => {
    const {translation: {artist: {sections}}} = useTranslations()

    return (
        <div className="artistIntro">
            <div className="artistIntro__menu">
                <div className="artistIntro__menu-title">
                    <h1>{`${name} ${lastname}`}</h1>
                </div>
                <div className="artistIntro__menu-list">
                    <ul>
                        {Object.keys(sections).map((section) => {
                            const {id, label} = sections[section]
                            return <li key={id}>
                                <CustomLink to={id}
                                            className={'artistIntro__menu-list-link'}>
                                    {label}
                                </CustomLink>
                            </li>
                        })}
                    </ul>
                </div>
                {/*
                  <div className="artistIntro__menu-pagination">
                        <div className="numbers">
                            <div className="numbers__current">02</div>
                            {" / "}
                            <div className="numbers__total">04</div>
                        </div>
                        <div className="buttons">
                            <button className="buttons__prev">Prev</button>

                            <button className="buttons__next">Next</button>
                        </div>
                    </div>
                */}
            </div>
            <div className="artistIntro__sliders">
                <Image title={`${name} ${lastname}`} imgSrc={image}/>
            </div>
        </div>
    )
}