/** Containers */
import { Layout } from '../../containers/layout';
/** Components */
import { IntroSection } from './components/introSection';
import { BiographySection } from './components/biographySection';
import { SelectedWorksSection } from './components/selectedWorksSection';
import { ExhibitionsSection } from './components/exhibitionsSection';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from '../../contexts/LanguageProvider';

export const Artist = () => {
    const {
        translation: { artists }, language
    } = useTranslations();
    const params = useParams();
    const [artist,setArtist] = useState(
        artists.persons.find((item) => item.id === params.name)
    );

    useEffect(()=>{
        setArtist(artists.persons.find((item) => item.id === params.name))
    }, [language, params.name, artists.persons])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Layout>
            <IntroSection {...artist} />
            <BiographySection {...artist.biography} />
            <SelectedWorksSection selectedWorks={artist.selectedWorks}/>
            <ExhibitionsSection exhibitions={artist.exhibitions}/>
        </Layout>
    );
};

export default Artist;
