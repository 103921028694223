import {Information} from "./components/Information";
import {Layout} from "../../containers/layout";
import {Services} from "./components/Services";

function Gallery() {
    return (
        <Layout section=''>
            <Information/>
            <Services/>
        </Layout>
    );
}

export default Gallery;
