
/** Containers */
import { Layout } from '../../containers/layout'
/** Components */
import { ExpoCard } from '../../components/cards/expoCard'
/** Constants */
import { expositions } from '../../constants/expositions'
/** Styles */
import './styles/expositions.scss'

function Expositions() {
    return (
        <Layout section='Expositions'>
        <div className="expositions">
            {
                expositions.map(({img, title, date, description}) => {
                    return <ExpoCard
                                key={`${title}_${date}`}
                                img={img} 
                                title={title} 
                                date={date} 
                                description={description}
                            />
                })
            }
        </div>
        </Layout>
    );
}

export default Expositions;
