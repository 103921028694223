export const expositions = [
  {
    id: 1,
    img: '',
    title: 'Exposition title 1',
    date: '9 de junio 2022 20 horas',
    description: 'Inauguración 9 de junio 20 horas Una nueva propuesta de Art Space y Lanzarote Art Gallery que reúne a tres mujeres artistas internacionales. De diversas geografías, con diferentes visiones y […]'
  },
  {
    id: 2,
    img: '',
    title: 'Exposition title 2',
    date: '10 de junio 2022 20 horas',
    description: 'Inauguración 10 de junio 20 horas Una nueva propuesta de Art Space y Lanzarote Art Gallery que reúne a tres mujeres artistas internacionales. De diversas geografías, con diferentes visiones y […]'
  },
  {
    id: 3,
    img: '',
    title: 'Exposition title 3',
    date: '14 de junio 2022 20 horas',
    description: 'Inauguración 14 de junio 20 horas Una nueva propuesta de Art Space y Lanzarote Art Gallery que reúne a tres mujeres artistas internacionales. De diversas geografías, con diferentes visiones y […]'
  },
  {
    id: 4,
    img: '',
    title: 'Exposition title 4',
    date: '18 de junio 2022 20 horas',
    description: 'Inauguración 18 de junio 20 horas Una nueva propuesta de Art Space y Lanzarote Art Gallery que reúne a tres mujeres artistas internacionales. De diversas geografías, con diferentes visiones y […]'
  },
  {
    id: 5,
    img: '',
    title: 'Exposition title 5',
    date: '26 de junio 2022 20 horas',
    description: 'Inauguración 26 de junio 20 horas Una nueva propuesta de Art Space y Lanzarote Art Gallery que reúne a tres mujeres artistas internacionales. De diversas geografías, con diferentes visiones y […]'
  },
  {
    id: 6,
    img: '',
    title: 'Exposition title 6',
    date: '29 de junio 2022 20 horas',
    description: 'Inauguración 29 de junio 20 horas Una nueva propuesta de Art Space y Lanzarote Art Gallery que reúne a tres mujeres artistas internacionales. De diversas geografías, con diferentes visiones y […]'
  },
]