import './styles.scss'
import {useEffect, useRef} from "react";
import animation from "./animation";
import {useTranslations} from "../../contexts/LanguageProvider";

const Canvas = (props) => {
    const {translate} = useTranslations()
    const canvasRef = useRef(null)

    useEffect(() => {
        if (canvasRef.current) {
            animation.startAnimation()
        }

        return () => {
            animation.cleanAll()
        }
    }, [])

    return (
        <>
            <canvas id="particle-canvas" className="particle-canvas" ref={canvasRef}  {...props}/>
            <div className="title">
                <h3>{translate('company.type')}</h3>
                <h1>{translate('company.shortName').toUpperCase()}</h1>
            </div>
        </>
    )
}

export default Canvas