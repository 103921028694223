import {Outlet} from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import RealmApp from "../contexts/Realm";
import MongoDB from "../contexts/MongoDB";
import LanguageProvider from "../contexts/LanguageProvider";
import './App.scss'


const App = () => {
    return (
        <RealmApp>
            <MongoDB>
                <LanguageProvider>
                    <Navbar/>
                    <Outlet/>
                    <Footer/>
                </LanguageProvider>
            </MongoDB>
        </RealmApp>
    )
}

export default App